<template>
  <div style="margin: 0 auto">
    <v-row>
      <v-col cols="12" offset-md="1" md="10" class="pa-0 mb-5">
        <v-row v-if="dashboard == 'teacher'">
          <v-col cols="4">
            <label for="ClassName">{{ $t("Class Name") }}: </label>
            <v-select
              :items="classes"
              item-text="class_name"
              item-value="id"
              v-model="selectedClass"
              dense
              outlined
            ></v-select>
          </v-col>
          <v-col cols="6">
            <label for="ClassName">{{ $t("Week") }}: </label>
            <v-select
              :items="weeks"
              item-text="name"
              item-value="id"
              v-model.trim="selectedWeekId"
              dense
              outlined
            >
              <template slot="selection" slot-scope="data">
                <!-- HTML that describe how select should render selected items -->
                {{ data.item.name }} {{ data.item.from }} : {{ data.item.to }}
              </template>
              <template slot="item" slot-scope="data">
                <!-- HTML that describe how select should render items when the select is open -->
                {{ data.item.name }} {{ data.item.from }} : {{ data.item.to }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="2">
            <v-btn class="main-btn mt-md-8" @click="changeWeek">{{
              $t("Show")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div class="header mb-0" v-if="weeks.length > 0 && dashboard != 'teacher'">
      <div class="dates">
        <v-icon style="color: #7195fa" @click="previousWeek">
          {{ "arrow_back_ios" }}
        </v-icon>
        {{ selectedWeekFrom }} - {{ selectedWeekTo }}
        <v-icon style="color: #7195fa" @click="nextWeek">
          {{ "arrow_forward_ios" }}
        </v-icon>
      </div>
      <v-row>
        <v-col cols="12" md="6" style="margin: 0 auto">
          <v-select
            :items="weeks"
            item-text="name"
            item-value="id"
            v-model.trim="selectedWeekId"
            solo
            @change="changeWeek"
            style="float: left; width: 67%"
          >
            <template slot="selection" slot-scope="data">
              <!-- HTML that describe how select should render selected items -->
              {{ data.item.name }} - {{ data.item.from }} : {{ data.item.to }}
            </template>
            <template slot="item" slot-scope="data">
              <!-- HTML that describe how select should render items when the select is open -->
              {{ data.item.name }} - {{ data.item.from }} : {{ data.item.to }}
            </template>
          </v-select>
          <v-btn
            v-can="'confirm-weekly-plan'"
            v-if="!weekDates[selectedWeekId].confirmed"
            class="main-btn"
            style="
              margin-right: 5px !important;
              height: 48px !important;
              width: 25%;
            "
            @click="changeStatus"
            :disabled="loading"
            :loading="loading"
            >{{ $t("Confirm") }}
          </v-btn>
          <v-icon
            style="color: #7195fa; margin-top: 7px; font-size: 35px"
            v-if="!weekDates[selectedWeekId].confirmed"
          >
            {{ "check_circle" }}
          </v-icon>

          <v-btn
            v-can="'confirm-weekly-plan'"
            v-if="weekDates[selectedWeekId].confirmed"
            class="main-btn"
            style="margin-right: 5px !important; height: 48px !important"
            @click="changeStatus"
            :disabled="loading"
            :loading="loading"
            >{{ $t("Un Confirm") }}
          </v-btn>
          <span
            style="color: rgb(4, 83, 4); font-size: 16px; font-weight: bold"
            v-if="weekDates[selectedWeekId].confirmed"
          >
            {{ "Confirmed" }}
          </span>

          <!-- <v-btn
            v-can="'confirm-weekly-plan'"
            @click="changeStatus"
            :title="
              weekDates[selectedWeekId].confirmed
                ? $i18n.t('Un Confirm')
                : $i18n.t('Confirm')
            "
            :disabled="loading"
            :loading="loading"
          >
            {{ $i18n.t("Confirm") }}
            <v-icon style="color: #7195fa; margin-top: 7px; font-size: 35px">
              {{
                weekDates[selectedWeekId].confirmed ? "cancel" : "check_circle"
              }}
            </v-icon>
          </v-btn> -->
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col cols="12" md="12" class="pa-0 mb-5">
        <div class="table-container" v-if="weeks.length > 0">
          <v-form ref="myForm" class="student-form" v-model="valid">
            <table class="scheduleTable weeklyPlan" style="width: 100%">
              <thead>
                <tr class="lesson-header">
                  <td class="day-column"></td>
                  <td
                    v-for="(lessonName, index) in scheduleLessonsNames"
                    :key="index"
                    class="lesson-td"
                  >
                    <span class="lesson-name mb-0">{{ lessonName.name }}</span>
                    <span
                      class="lesson-name mb-0"
                      v-if="!lessonName.name && editMode"
                    >
                      {{ "break" }}
                    </span>
                  </td>
                </tr>
                <tr class="time-header">
                  <td></td>
                  <td
                    v-for="(time, index) in schedulesFromTo"
                    :key="index"
                    class="time-item"
                  >
                    <span class="from">
                      {{ time.from ? getFullTime(time.from) : $t("From") }}
                    </span>
                    <span class="to">
                      {{ time.to ? getFullTime(time.to) : $t("To") }}
                    </span>
                  </td>
                </tr>
              </thead>
              <tbody v-for="(schedule, dayIndex) in schedules" :key="dayIndex">
                <tr class="full-day">
                  <td class="day">
                    <span class="d-inline-block day-content">
                      {{ $t(schedule.day) }}
                      <v-icon
                        :title="schedule.day_notes"
                        style="font-size: 35px"
                        @click="openDayNoteModal(dayIndex)"
                        v-if="schedule.day_notes || canManageDayNotes"
                        color="white"
                        >{{ "speaker_notes" }}</v-icon
                      >
                    </span>
                  </td>
                  <td
                    :class="
                      lessonCard.subject && editable
                        ? 'lesson-card hover'
                        : 'lesson-card'
                    "
                    v-for="(lessonCard, index) in schedule.lessons"
                    :key="index"
                    @click="openModal(dayIndex, index)"
                  >
                    <div
                      v-if="
                        lessonCard.type == 'lesson' &&
                        lessonCard.class &&
                        !teacherId
                      "
                      class="class-item"
                    >
                      {{ lessonCard.class }}
                    </div>
                    <div
                      class="subject"
                      v-if="lessonCard.type == 'lesson' && !teacherId"
                    >
                      {{ lessonCard.subject }}
                    </div>
                    <div
                      class="teacher"
                      v-if="lessonCard.type == 'lesson' && !teacherId"
                    >
                      {{ lessonCard.teacher }}
                    </div>
                    <div
                      class="teacher"
                      v-if="
                        lessonCard.type == 'lesson' &&
                        lessonCard.lesson_id &&
                        !teacherId
                      "
                    >
                      <p
                        class="note"
                        :title="lessonCard.class_work_notes"
                        v-if="lessonCard.class_work_notes && !teacherId"
                      >
                        <span style="font-weight: bold"
                          >{{ $t("C.W") }} {{ $t("Notes") }}:</span
                        >
                        {{
                          lessonCard.class_work_notes &&
                          lessonCard.class_work_notes.length >= 16
                            ? lessonCard.class_work_notes.substring(0, 15)
                            : lessonCard.class_work_notes
                        }}
                      </p>
                      <p
                        class="note"
                        :title="lessonCard.home_work_notes"
                        v-if="lessonCard.home_work_notes && !teacherId"
                      >
                        <span style="font-weight: bold"
                          >H.W {{ $t("Notes") }}:</span
                        >
                        {{
                          lessonCard.home_work_notes &&
                          lessonCard.home_work_notes.length >= 16
                            ? lessonCard.home_work_notes.substring(0, 15)
                            : lessonCard.home_work_notes
                        }}
                      </p>
                      <p
                        class="note"
                        :title="lessonCard.notes"
                        v-if="lessonCard.notes && !teacherId"
                      >
                        <span style="font-weight: bold"
                          >{{ $t("Notes") }}:</span
                        >
                        {{
                          lessonCard.notes && lessonCard.notes.length >= 16
                            ? lessonCard.notes.substring(0, 15)
                            : lessonCard.notes
                        }}
                      </p>
                    </div>
                    <div
                      class="break"
                      v-if="lessonCard.type == 'break' && !teacherId"
                    >
                      <span>{{ $t("BREAK") }}</span>
                    </div>
                    <div v-if="teacherId" style="height: 100%">
                      <!-- v-for="(lessonItem, i) in lessonCard" -->
                      <div
                        :key="i"
                        class="lesson-container"
                        :class="lessonCard.type == 'break' ? 'full-height' : ''"
                      >
                        <div
                          v-if="
                            lessonCard.type == 'lesson' &&
                            lessonCard.subject &&
                            teacherId
                          "
                          class="single-lesson"
                          :class="lessonCard.current ? 'active' : ''"
                        >
                          <div class="subject-item">
                            {{
                              lessonCard.from
                                ? getFullTime(lessonCard.from) + " :"
                                : ""
                            }}
                            {{
                              lessonCard.to ? getFullTime(lessonCard.to) : ""
                            }}
                          </div>
                          <div class="class-item">{{ lessonCard.class }}</div>
                          <div class="subject-item">
                            {{ lessonCard.subject }}
                          </div>

                          <div
                            v-if="lessonCard.meeting_active && canOpenMeeting"
                          >
                            <a
                              target="_blank"
                              :href="FrameLink + lessonCard.meetingId"
                            >
                              <img
                                data-v-38104988
                                src="../../assets/elearning.svg"
                                width="25"
                              />
                              <strong class="main-color">{{
                                $t("Classroom")
                              }}</strong>
                            </a>
                          </div>

                          <div
                            class="teacher"
                            v-if="
                              lessonCard.type == 'lesson' &&
                              lessonCard.lesson_id
                            "
                          >
                            <p
                              class="note"
                              :title="lessonCard.class_work_notes"
                              v-if="lessonCard.class_work_notes"
                            >
                              <span style="font-weight: bold"
                                >C.W {{ $t("Notes") }}:</span
                              >
                              {{
                                lessonCard.class_work_notes &&
                                lessonCard.class_work_notes.length >= 16
                                  ? lessonCard.class_work_notes.substring(0, 15)
                                  : lessonCard.class_work_notes
                              }}
                            </p>
                            <p
                              class="note"
                              :title="lessonCard.home_work_notes"
                              v-if="lessonCard.home_work_notes"
                            >
                              <span style="font-weight: bold"
                                >H.W {{ $t("Notes") }}:</span
                              >
                              {{
                                lessonCard.home_work_notes &&
                                lessonCard.home_work_notes.length >= 16
                                  ? lessonCard.home_work_notes.substring(0, 15)
                                  : lessonCard.home_work_notes
                              }}
                            </p>
                            <p
                              class="note"
                              :title="lessonCard.notes"
                              v-if="lessonCard.notes"
                            >
                              <span style="font-weight: bold"
                                >{{ $t("Notes") }}:</span
                              >
                              {{
                                lessonCard.notes &&
                                lessonCard.notes.length >= 16
                                  ? lessonCard.notes.substring(0, 15)
                                  : lessonCard.notes
                              }}
                            </p>
                          </div>
                        </div>
                        <div
                          class="break"
                          v-if="lessonCard.type == 'break' && teacherId"
                        >
                          <span>{{ $t("BREAK") }}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-form>
        </div>
        <div v-else class="no-data text-center">
          {{ $t("No data available") }}
        </div>
        <v-dialog
          v-model="AddEditdialog"
          persistent
          max-width="500px"
          scrollable
          v-if="editable"
        >
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t("Add Notes") }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <!-- <v-col cols="12" sm="12" md="12" style="text-align: center;">
                <div v-if="errMessage">
                  <p class="red--text">
                    {{ errMessage }}
                  </p>
                </div>
              </v-col>-->
                  <v-col cols="12" sm="12" md="12">
                    <label style="margin-top: 16px !important">{{
                      $t("Classwork Notes")
                    }}</label>
                    <v-textarea
                      v-model="editedItem.class_work_notes"
                      solo
                      rows="3"
                      style="font-size: 13px"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <label>{{ $t("Homework Notes") }}</label>
                    <v-textarea
                      v-model="editedItem.home_work_notes"
                      solo
                      rows="3"
                      style="font-size: 13px"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <label>{{ $t("Notes") }}</label>
                    <v-textarea
                      v-model="editedItem.notes"
                      solo
                      rows="3"
                      style="font-size: 13px"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn class="modal-btn-cancel" @click="close">{{
                $t("Close")
              }}</v-btn>
              <v-btn
                class="modal-btn-save"
                @click="saveNotes"
                :loading="loading"
                :disabled="loading"
                >{{ $t("Save") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="AddEditDayNoteDialog"
          persistent
          max-width="500px"
          scrollable
          v-if="editable && canManageDayNotes"
        >
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t("Add Day Notes") }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    style="text-align: center; margin-top: 20px"
                  >
                    <p class="main-color class-item">{{ $t(noteOnDay) }}</p>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <label>{{ $t("Day Notes") }}</label>
                    <v-textarea
                      v-model="editedItemDay.day_notes"
                      solo
                      rows="3"
                      style="font-size: 13px"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn class="modal-btn-cancel" @click="closeDayNotesDialog">{{
                $t("Close")
              }}</v-btn>
              <v-btn
                class="modal-btn-save"
                @click="saveDayNotes"
                :loading="loading"
                :disabled="loading"
                >{{ $t("Save") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { weeklyPlanMixin } from "../../mixins/schedules/weeklyPlanMixin";
import ACL from "../../acl";
import axios from "axios";

export default {
  mixins: [weeklyPlanMixin],
  props: ["editable", "dashboard"],
  data() {
    return {
      canOpenMeeting: ACL.Role("Teacher") || ACL.Role("Student"),
      FrameLink: window.location.origin + "/zoomIframe/",
      schedules: [],
      scheduleLessonsNames: [],
      schedulesFromTo: [],
      weeks: [],
      weekDates: [],
      editMode: false,
      //academicId: this.$router.currentRoute.params.academicYear,
      //classId: this.$router.currentRoute.params.classId,
      loading: false,
      valid: false,
      editedItem: {
        class_work_notes: "",
        home_work_notes: "",
        notes: "",
        lesson_id: "",
        academic_week_id: "",
      },
      editedItemDay: {
        day_notes: "",
        week_day: "",
        academic_week_id: "",
        class_id: "",
      },
      editedWeekDay: "",
      selectedWeekId: "",
      selectedWeekFrom: "",
      selectedWeekTo: "",
      AddEditdialog: false,
      AddEditDayNoteDialog: false,
      noteOnDay: "",
      canManageDayNotes: ACL.checkPermission("manage-day-notes"),
      classes: [],
      selectedClass: "",
    };
  },
  methods: {
    getClasses() {
      axios
        .get(this.getApiUrl + "/teachers/classes", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((res) => {
          this.classes = res.data.data;
        });
    },
    getFullTime(time) {
      var type = "";
      var H = time.split(":")[0];
      var M = time.split(":")[1];
      if (H == "00") {
        H = 12;
        type = this.$i18n.t("AM");
      } else if (H == "12") {
        type = this.$i18n.t("PM");
      } else if (parseInt(H) > 12) {
        type = this.$i18n.t("PM");
        H = H % 12;
      } else if (parseInt(H) < 12) {
        type = this.$i18n.t("AM");
      }
      return H + ":" + M + " " + type;
    },
    openModal(dayIndex, lesson_index) {
      if (
        this.schedules[dayIndex].lessons[lesson_index].subject &&
        !this.weekDates[this.selectedWeekId].confirmed
      ) {
        this.editedItem = {
          class_work_notes:
            this.schedules[dayIndex].lessons[lesson_index].class_work_notes,
          home_work_notes:
            this.schedules[dayIndex].lessons[lesson_index].home_work_notes,
          notes: this.schedules[dayIndex].lessons[lesson_index].notes,
          lesson_id: this.schedules[dayIndex].lessons[lesson_index].lesson_id,
          academic_week_id: this.selectedWeekId,
        };
        console.log(this.editedItem);
        this.AddEditdialog = true;
      }
    },
    openDayNoteModal(dayIndex) {
      if (!this.weekDates[this.selectedWeekId].confirmed) {
        this.noteOnDay = this.schedules[dayIndex].day;
        this.editedItemDay = {
          day_notes: this.schedules[dayIndex].day_notes,
          week_day: this.schedules[dayIndex].day,
          academic_week_id: this.selectedWeekId,
          class_id: this.classId,
        };
        this.AddEditDayNoteDialog = true;
      }
    },
    close() {
      this.editedItem = {
        class_work_notes: "",
        home_work_notes: "",
        notes: "",
        lesson_id: "",
        academic_week_id: "",
      };
      this.AddEditdialog = false;
    },
    closeDayNotesDialog() {
      this.editedItemDay = {
        day_notes: "",
        week_day: "",
        academic_week_id: "",
        class_id: "",
      };
      this.AddEditDayNoteDialog = false;
    },
    changeWeek() {
      this.selectedWeekFrom = this.weekDates[this.selectedWeekId].from;
      this.selectedWeekTo = this.weekDates[this.selectedWeekId].to;
      this.getNotes();
    },
    nextWeek() {
      let selectedWeekId = this.selectedWeekId;
      let takeNext = false;
      let _this = this;
      Object.keys(this.weekDates).forEach(function (key) {
        if (takeNext) {
          _this.selectedWeekId = parseInt(key);
          _this.selectedWeekFrom = _this.weekDates[key].from;
          _this.selectedWeekTo = _this.weekDates[key].to;
          takeNext = false;
        }
        if (key == selectedWeekId) {
          takeNext = true;
        }
      });
      this.getNotes();
    },
    previousWeek() {
      // console.log("here");
      let selectedWeekId = this.selectedWeekId;
      let takelast = false;
      let last = null;
      let _this = this;
      Object.keys(this.weekDates).forEach(function (key) {
        if (key == selectedWeekId) {
          takelast = true;
        }
        if (takelast && _this.weeks[0].id != key) {
          _this.selectedWeekId = parseInt(last);
          _this.selectedWeekFrom = _this.weekDates[last].from;
          _this.selectedWeekTo = _this.weekDates[last].to;
          takelast = false;
        }
        last = key;
      });
      this.getNotes();
    },
  },
  mounted() {
    // console.log("role", ACL.Role("Teacher"));
    // setTimeout(
    // () => {
    this.getNotes();
    // },
    // this.HiddenNoData ? 1000 : 0
    // );
    localStorage.activeTab = 2;
    // this.drawTable();
    this.getClasses();
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/ScheduleTable.css";
@import "../../styles/_variables.scss";

.full-day {
  .day {
    position: relative;
    .day-content {
      transform: rotate(-90deg);
      background-color: $third-color;
      color: #fff;
      padding: 0 7px;
      border-radius: 8px 8px 0 0;
      position: absolute;
      right: -40px;
    }
  }
}

.lesson-header {
  .lesson-name {
    text-align: center;
    position: absolute;
    padding-bottom: 0;
    font-size: 20px;

    left: 38%;
    border: 1px solid $third-color;
    border-bottom: 0;
    display: inline-block;
    padding: 4px 20px;
    top: -12px;
    border-radius: 10px 10px 0 0;

    width: auto !important;
  }
  .lesson-name.active {
    background-color: $third-color;
    color: #fff;
  }
}

.single-lesson.active {
  border: 1px solid $third-color;
  box-shadow: 0 0 6px 0 var(--robins-egg);
}
</style>
