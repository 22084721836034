<template>
  <div id="manageClass">
    <!-- breadCrumb -->
    <v-breadcrumbs :items="breadcrumbList" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <!-- breadCrumb -->
    <v-row>
      <v-col cols="12">
        <div class="title">
          <h1>{{ className }}</h1>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" class="no-padding">
        <v-tabs v-model="tab" class="tabs" background-color="#ffffff" dark>
          <v-tab
            v-for="(tab_f, index) in tab_index"
            :key="index"
            @click="routeTab(index)"
            >{{ tab_f.name }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-card class="padding-top">
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item v-for="(tab_f, index) in tab_index" :key="index">
          <!-- Students -->
          <v-card flat v-if="tab_f.permission_name == 'listStudentsVisible'">
            <v-card-text>
              <DataTableComponent :table="table"></DataTableComponent>
            </v-card-text>
          </v-card>
          <!-- Schedule -->
          <v-card flat v-if="tab_f.permission_name == 'scheduleVisible'">
            <v-card-text>
              <ScheduleComponent
                :editable="true"
                :classId="classId"
                :academicId="academicId"
                :vacations="false"
                :HiddenNoData="false"
              ></ScheduleComponent>
            </v-card-text>
          </v-card>
          <!-- Weekly Plane -->
          <v-card flat v-if="tab_f.permission_name == 'weeklyPlanVisible'">
            <v-card-text>
              <WeeklyPlanComponent
                :editable="true"
                :classId="classId"
                :academicId="academicId"
                :teacherId="null"
              ></WeeklyPlanComponent>
            </v-card-text>
          </v-card>
          <!-- Exams -->
          <v-card flat v-if="tab_f.permission_name == 'listExamsVisible'">
            <v-card-text>
              <DataTableComponent :table="table_exam"></DataTableComponent>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import ACL from "../../acl";
import DataTableComponent from "../../components/DataTableComponent";
import ScheduleComponent from "../../components/Schedule/ScheduleComponent";
import WeeklyPlanComponent from "../../components/Schedule/WeeklyPlanComponent";
export default {
  name: "ManageClass",
  components: { DataTableComponent, ScheduleComponent, WeeklyPlanComponent },

  data() {
    return {
      tab: null,
      tab_index: [
        {
          permission: ACL.checkPermission("list-student-assign-to-class"),
          permission_name: "listStudentsVisible",
          name: this.$i18n.t("Students"),
        },
        {
          permission: ACL.checkPermission("manage-schedule"),
          permission_name: "scheduleVisible",
          name: this.$i18n.t("Schedule"),
        },
        {
          permission: ACL.checkPermission("edit-weekly-plan"),
          permission_name: "weeklyPlanVisible",
          name: this.$i18n.t("Weekly Plan"),
        },
        {
          permission: ACL.checkPermission("list-exams"),
          permission_name: "listExamsVisible",
          name: this.$i18n.t("Exams"),
        },
      ],
      dialog_exam: false,
      exam: {
        name: "",
        subject_id: "",
        qualifier_id: "",
        mark: "",
        date: "",
        academic_year_id: "",
      },
      search: "",
      subjects: [],
      qualifiers: [],
      academicPeriods: [],
      classData: {},
      academicId: this.$router.currentRoute.params.academicYear,
      classId: this.$router.currentRoute.params.classId,
      className: "",
      tab_exam_number: "",
      tab_student_number: "",
      table_exam: {
        modal: "Exam",
        path: "manageclass/",
        tabNumber: "",
        headers: [
          {
            text: this.$i18n.t("Name"),
            value: "name",
            sortable: true,
          },
          {
            text: this.$i18n.t("Subject"),
            value: "subject",
            sortable: true,
          },
          {
            text: this.$i18n.t("Qualifiers"),
            value: "qualifier", // not sortable item
            sortable: true,
          },
          {
            text: this.$i18n.t("Mark"),
            value: "mark", // not sortable item
            sortable: false,
          },
          {
            text: this.$i18n.t("Date"),
            value: "exam_date", // not sortable item
            sortable: true,
          },
          /*  {
            text: "Academic Period",
            value: "academic_year", // not sortable item
            sortable: false,
          }, */

          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            width: "3%",
          },
        ],
        filters: {
          subjects: [],
          qualifiers: [],
          academicPeriods: [],
          quarters:[],
        },
        title: this.$i18n.t("Exams"),
        apiEndPoints: {
          list: "exams/" + this.$router.currentRoute.params.classId,
          create: "exams/store",
          edit: "exams/update",
          delete: "exams/destroy",
        },
        editedItem: {
          name: "",
          subject_id: "",
          qualifier_id: "",
          mark: "",
          exam_date: "",
          academic_year_id: "",
          randomiz: "",
          class_id: this.$router.currentRoute.params.classId,
          students: [],
        },
        permissions: {
          list: "list-exams",
          add: "add-exams",
          edit: "edit-exams",
          delete: "delete-exams",
          show: "list-exams",
        },
      },
      table: {
        modal: "assign student to class",
        path: "manageclass/",
        tabNumber: "",
        headers: [
          // {
          //   text: "Plan ID",
          //   value: "id"
          // },
          {
            text: this.$i18n.t("Code"),
            value: "code",
            sortable: true,
          },
          {
            text: this.$i18n.t("Name"),
            value: "student_full_name", // not sortable item
            sortable: true,
          },
          {
            text: this.$i18n.t("Gender"),
            value: "student_gender", // not sortable item
            sortable: false,
          },
          /*    {
            text: "Nationality",
            value: "student_nationality", // not sortable item
            sortable: false,
          },
          {
            text: this.$i18n.t("Date of Birth"),
            value: "student_birthdate", // not sortable item
            sortable: false,
          },
          // {
          //   text: this.$i18n.t("GPA"),
          //   value: "", // not sortable item
          //   sortable: false,
          // },

          {
            text: "GPA",
            value: "", // not sortable item
            sortable: false,
          },
*/
          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            width: "3%",
          },
        ],
        title: this.$i18n.t("Students"),
        apiEndPoints: {
          list:
            "students/listStudentClasses/" +
            this.$router.currentRoute.params.classId,
          create:
            "students/assignStudentToClass/" +
            this.$router.currentRoute.params.classId,
          edit:
            "students/assignStudentToClass/" +
            this.$router.currentRoute.params.classId,
          delete:
            "students/deleteStudentFromClass/" +
            this.$router.currentRoute.params.classId,
          printList:
            "students/printPdfStudentAssignToClass/" +
            this.$router.currentRoute.params.classId,
          printParentMeetings:
            "students/printPdfStudentAssignToClassWithSignature/" +
            this.$router.currentRoute.params.classId,
        },
        editedItem: {
          students: [],
        },
        permissions: {
          list: "list-student-assign-to-class",
          add: "add-student-assign-to-class",
          edit: "edit-student-assign-to-class",
          delete: "delete-student-assign-to-class",
          upgradeStudent: "student-upgrade",
        },
      },
      listStudentsVisible: ACL.checkPermission("list-student-assign-to-class"),
      listExamsVisible: ACL.checkPermission("list-exams"),
      scheduleVisible: ACL.checkPermission("manage-schedule"),
      weeklyPlanVisible: ACL.checkPermission("edit-weekly-plan"),
    };
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.query.tab !== undefined) {
          this.tab = Number(this.$route.query.tab);
        }
      },
    },
  },
  methods: {
    routeTab(tab) {
      this.$router.push(
        {
          path:
            "/manageclass/" +
            this.academicId +
            "/" +
            this.classId +
            "?tab=" +
            tab +
            "&page=1",
        },
        () => {}
      );
      // localStorage.tab = tab;
    },
    getClassData() {
      axios
        .get(this.getApiUrl + "/classes/show/" + this.classId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.className = response.data.data.name;
        });
    },
    // getQuartersForFilter(){
    //   axios
    //     .get(
    //       this.getApiUrl +
    //       "/exams/getQuartersForFilter/" +
    //       this.academicId,
    //       {
    //         headers: {
    //           Authorization: "Bearer " + localStorage.token,
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       this.table_exam.filters.quarters = response.data.data;
    //       console.log(this.table_exam.filters.quarters);
    //     });
    // }
  },
  mounted() {
    this.academicId = this.$router.currentRoute.params.academicYear;
    this.classId = this.$router.currentRoute.params.classId;

    this.getClassData();
    // this.getQuartersForFilter();
    this.tab_index = this.tab_index.filter(function (item) {
      return item.permission == true;
    });

    for (let index = 0; index < this.tab_index.length; index++) {
      if (this.tab_index[index].name == "Students") {
        this.tab_student_number = Number(index);
        this.table.path += Number(index);
        this.table.tabNumber = Number(index);
      }

      if (this.tab_index[index].name == "Exams") {
        this.tab_exam_number = Number(index);
        this.table_exam.path += Number(index);
        this.table_exam.tabNumber = Number(index);
      }
    }

    axios
      .get(
        this.getApiUrl +
          "/exams/getAcademic_periods/" +
          this.academicId +
          "/" +
          this.$route.params.classId,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        }
      )
      .then((response) => {
        this.table_exam.filters.academicPeriods = response.data.data.quarter;
      });

    

    axios
      .get(this.getApiUrl + "/exams/getSubjects/" + this.classId, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        this.table_exam.filters.subjects = response.data.data;
      });
    // this.tab = parseInt(localStorage.activeTab);
    if (this.$route.query.tab !== undefined) {
      this.tab = Number(this.$route.query.tab);
    } else {
      this.tab = 0;
    }

    // this.tab = localStorage.tab;
    // this.routeTab(localStorage.tab);
  },
};
</script>

<style lang="scss" scoped>
.add-icon {
  min-height: 15rem;
  line-height: 15rem;
}

.icons,
.addIcon-div {
  margin-top: 1rem;
}
</style>
